export const ENVIRONMENT = {
  'app.pando.com': 'production',
  'app.pandohr.com': 'production',
  'app.rhodnap.com': 'staging',
  'pando-app.onrender.com': 'staging',
}[window.location.hostname] || 'development';

export const PANDO_API_URL = (function getAPIURL() {
  const fromHash = {
    'app.pando.com': 'https://api.pando.com',
    'app.pandohr.com': 'https://api.pandohr.com',
    'app.rhodnap.com': 'https://api.rhodnap.com',
    'pando-app.onrender.com': 'https://pando-api.onrender.com',
  }[window.location.hostname];
  if (fromHash) return fromHash;

  const fromRender = window.location.hostname.match(/^pando-app-pr-([0-9]+)\.onrender\.com$/);
  if (fromRender) return `https://pando-api-pr-${fromRender[1]}.onrender.com`;

  return 'http://localhost:8081';
}());

export const SENTRY = {
  TRACING_ORIGINS: [
    'pando-app-pr-*.onrender.com',
    'app.pando/*',
  ],
  DSN: 'e894754180174f3181ea888e119d43c4@o1229261',
  PROJECT_ID: '6375287',
};

export const VITE_GIT_BRANCH = import.meta.env.VITE_GIT_BRANCH || 'local';

export const VITE_GIT_COMMIT = import.meta.env.VITE_GIT_COMMIT || 'unreleased';
